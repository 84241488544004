export const stepsInfo = [
	{
		paragraph: 'Зарегистрироваться в качестве самозанятого. Это просто и быстро. Такой шаг позволит вам заработать больше на своих произведениях',
		greyTexts: 'Если вы уже зарегистрированы в качестве самозанятого, то переходите к шагу 2'
	},
	{
		paragraph: 'Высылайте свои музыкальные произведения на адрес: ',
		link: 'muzred@zaycev.net',
		greyTexts: 'Внимание: произведений должно быть не меньше 10. Вам должно принадлежать 100% авторских прав и 100% смежных прав'
	},
	{
		paragraph: 'После того как получите на свой электронный адрес подтверждение, скачайте договор и приложение. Заполните все оригиналы и направьте нам по адресу: ',
		link: 'v.zharkova@zaycev.net'
	},
	{
		paragraph: 'Вы получите доступ в Личный кабинет, после чего, сможете загрузить свои произведения к нам на сайт.'
	},
	{
		paragraph: 'Все Ваши произведения появятся на сайте в открытом доступе после того как мы получим оригинал договора и приложения по адресу: 125315. г. Москва, ул. Лизы Чайкиной 6 ООО “ZAYCEV.NET”'
	},
	{
		paragraph: 'По итогам каждого отчетного периода мы будем высылать Вам отчет о потреблении ваших песен и выплачивать гонорар.'
	}
];
