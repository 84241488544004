import styled from 'styled-components';

export const Graytext = styled.p`
margin-top: 4px;
color: #646464;
font-style: italic;
font-size: 19px !important;
@media screen and (max-width: 576px) {
  font-size: 16px !important;
}
`;

export const Flex = styled.div`
display: flex;
align-items: center;
margin-bottom: 38px;
flex-direction: ${({reverse}) => (reverse ? 'row-reverse' : 'row')};

@media screen and (max-width: 576px) {
  flex-direction: column;

  img {
    width: 150px;
    margin-bottom: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none;
  }
}

p {
  font-size: ${({size}) => (size || '23px')};
  line-height: ${({line}) => (line || '34px ')};
}
`;

export const Button = styled.button`
margin: 18px 25px 0 0;
width: 190px;
height: 42px;
cursor: pointer;

background: #396265;
border-radius: 5px;
display: flex;
justify-content: center;
align-items: center;
color: #FFFFFF;
font-size: 15px;
line-height: 25px;
border: none;
:hover {
  background: #4C7B7E;
}
:active {
  background: #396265;
}
`;

export const ContentWrapper = styled.div`
margin-left: ${({reverse}) => (reverse ? 0 : '30px')};
a {
  display: inline-block;
}

@media screen and (max-width: 576px)  {
  margin-left: 0;
}

a{
  text-decoration: none;
}
`;
