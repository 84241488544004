import styled from 'styled-components';

export const Wrapper = styled.main`
max-width: 1038px;
padding: 50px 15px 200px 15px;
margin: 0 auto;

@media screen and (max-width: 576px)  {
	padding: 0 15px 100px 15px;
}
`;

export const Bold = styled.span`
font-weight: bold;
`;
