import styled from 'styled-components';

export const Link = styled.a`
font-size: inherit;
color: inherit;
text-decoration: none;
cursor: pointer;
border-bottom: 1px solid #1F4E51;

:hover {
    border: none;
    color: #4C7B7E;
}
:active {
    color: inherit;
    border-bottom: 1px solid #1F4E51;
}
`;

export const Paragraph = styled.p`
font-size: 23px;
line-height: 34px;
margin-bottom: ${({bottom}) => (bottom || 0)};

@media screen and (max-width: 576px) {
    font-size: 18px !important; 
    margin-bottom: ${({bottom}) => (bottom && parseFloat(bottom) / 2 + 'px')};
}
`;
