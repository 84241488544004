import React from 'react';
import PropTypes from 'prop-types';
import {Link, Paragraph} from '../../Common/common.styled';
import {
	Button, ContentWrapper, Flex, Graytext
} from '../style/Steps.styled';

const Step = ({
	icon, paragraph, greyText, button, reverse, link
}) => (
	<Flex reverse={reverse}>
		<img src={icon} alt={icon} />
		<ContentWrapper reverse={reverse}>
			<Paragraph>
				{paragraph}
				{link && <Link href={`mailto:${link}`}>{link}</Link>}
			</Paragraph>
			{greyText
            && <Graytext>{greyText}</Graytext>}
			{/* {button && (
				<a href="/dogovor/dogovor-self-employed.doc" target="_blank" download>
				<Button>Скачать договор</Button>
				</a>
			)} */}
		</ContentWrapper>
	</Flex>
);

Step.propTypes = {
	icon: PropTypes.string,
	paragraph: PropTypes.string,
	greyText: PropTypes.string,
	button: PropTypes.bool,
	reverse: PropTypes.number,
	link: PropTypes.string
};
export default Step;
