import React from 'react';
import PropTypes from 'prop-types';
import logo from '../../assets/logo.svg';
import mainlogo from '../../assets/main-icon.svg';
import {
	StyledHeader, Wrapper
} from './style/Header.styled';

const handleContextMenu = () => false;

const Header = ({smallHeader}) => (
	<>
		<StyledHeader scroll={smallHeader}>
			<a href="https://zaycev.net"
				rel="noopener noreferrer"
				target="_blank"
			>
				<img src={logo} alt="logo" onContextMenu={handleContextMenu} />
			</a>
		</StyledHeader>
		<Wrapper>
			<img src={mainlogo} alt="rabbit logo" onContextMenu={handleContextMenu} />
			<h1>Заключите лицензионное соглашение с ZAYCEV.NET</h1>
		</Wrapper>
	</>
);

Header.propTypes = {
	smallHeader: PropTypes.bool
};
export default Header;
