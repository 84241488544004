import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';

import './index.css';
import Main from './Pages/Main';

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN
	});
}

ReactDOM.render(<Main/>, document.getElementById('root'));

serviceWorker.unregister();
