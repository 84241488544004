import React, {useCallback, useEffect, useState} from 'react';
import {Link, Paragraph} from '../../components/Common/common.styled';
import Header from '../../components/Header/Header';
import {
	Bold, Wrapper
} from './styled/Main.styled';
import Steps from '../../components/Steps';

const Main = () => {
	const [scroll, setScroll] = useState(false);

	const onScroll = useCallback(e => {
		e.target.scrollingElement.scrollTop === 0 ? setScroll(false) : setScroll(true);
	}, []);

	useEffect(() => {
		document.addEventListener('scroll', onScroll);
		return () => {
			document.removeEventListener('scroll', onScroll);
		};
	}, [onScroll]);
	return (
		<>
			<Header smallHeader={scroll} />
			<Wrapper >
				<Paragraph bottom="40px"><Bold>Если вы являетесь юридическим лицом, индивидуальным предпринимателем или самозанятым,</Bold> напишите нам, мы с вами свяжемся и обо всем договоримся <Link href="mailto:legal@zaycev.net">legal@zaycev.net</Link></Paragraph>
				<Paragraph bottom="40px"><Bold>Если вы являетесь физическим лицом,</Bold> то для заключения лицензионного соглашения необходимо выполнить следующие шаги:</Paragraph>
				<Steps />
			</Wrapper>
		</>
	);
};

export default Main;
