/* eslint-disable camelcase */
import React from 'react';

import rabbit_1 from '../../assets/rabbits/icon_1.png';
import rabbit_2 from '../../assets/rabbits/icon_2.png';
import rabbit_3 from '../../assets/rabbits/icon_3.png';
import rabbit_4 from '../../assets/rabbits/icon_4.png';
import rabbit_5 from '../../assets/rabbits/icon_5.svg';
import rabbit_6 from '../../assets/rabbits/icon_6.svg';
import Step from './Step/Step';

import {stepsInfo} from './variable/StepsInfo';

const icons = [rabbit_1, rabbit_2, rabbit_3, rabbit_4, rabbit_5, rabbit_6];

const Steps = () => (
	<>
		{stepsInfo.map((info, index) => (
			<Step key={index}
				icon={icons[index]}
				paragraph={info.paragraph}
				greyText={info.greyTexts}
				reverse={index % 2}
				button={index === 2}
				link={info.link}
			/>
		))}
	</>
);

export default Steps;
