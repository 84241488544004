import styled from 'styled-components';

export const StyledHeader = styled.header`
position: fixed;
height: ${({scroll}) => (scroll ? '55px' : '80px')};
width: 100%;
background-color: #3C8E99;
display: flex;
justify-content: center;
align-items: center;
transition: all 0.3s ease;

img {
    height: ${({scroll}) => (scroll ? '19px' : '32px')};
    transition: all 0.3s ease;

`;

export const Wrapper = styled.div`
display: flex;
padding: 112px 10px;
margin: 0 auto;
max-width: 842px;
align-items: center;
justify-content: flex-start;

h1 {
    margin-left: 30px;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 0.02em;
    color: rgba(255, 255, 255, 0.98); 
}

@media screen and (max-width: 576px) {
    padding: 112px 10px 90px 10px;
    h1 {
        font-size: 26px;
        line-height: 40px;
        margin-left: 20px;
    }
    img {
        max-width: 150px;
    }
}
@media screen and (max-width: 390px) {
    img {
        max-width: 120px;
    }
}
@media screen and (max-width: 360px) {
    h1 {
        font-size: 24px;
        line-height: 36px;
        margin-left: 10px;
    }
    img {
        max-width: 110px;
    }
}
`;
